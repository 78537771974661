import React from "react"
import '../styles/Footer.css'
import { FaFacebook,  FaInstagram, FaTwitter,  } from 'react-icons/fa';

const Footer = ()=>{
    return (
        <section>
             <footer>
             <div id="container3" class="mainContent1">
    <div class="col">
    <div class="col">
        <h4>About</h4>
        <a href="/contact">Contact</a>
        <a href="/about">Who we are</a>        
         
           
    </div>
        {/* <div class="social-media">
            <h4>Follow Us</h4>
            <div class="social-icon">
            <li><a href=""><i ><FaInstagram/></i></a></li>
            <li><a href=""><i ><FaFacebook/></i></a></li>
            <li><a href=""><i ><FaTwitter/></i></a></li>
             </div>
        </div> */}
    </div>                     
    {/* <div class="col">
        <h4>Legal</h4>
        <a href="/privacypolicy">Privacy Policy</a>
        <a href="/privacypolicy">Terms & Services</a>                  
    </div>         */}
    <div class="col">
        <h4>Services</h4>
        <a href="/kitchen">Repair & remodel</a>
        <a href="/bathroom">Bathroom</a>
        {/* <a href="/livingroom">Living Room</a>
        <a href="/outdoor">Outdoor</a> */}
        <a href="/floor">Floors</a>
        <a href="/fences">Fences</a>

    </div>
    {/* <div class="col">
        <h4>Services</h4>         
        <a href="/tile">Ceramic & Porcelain Tiles</a>
        <a href="/">Installing Countertops</a>
        <a href="/">Backsplash</a>
        <a href="/">Chimneys</a>
    
    </div> */}
        
    {/* <div class="col install">
        <h4>Download the App</h4>
        <p>From App Store or Google Play</p>
        <div class="row">
            <img src="/images/google-play-badge.png" alt=""/>
            <img src="/images/app-store-badge.png" alt=""/>
            <p>Secure Payment Gateway</p> 
            <img class="pay" src="/images/payments.png" alt=""/>
        </div>
    </div> */}

 </div>
             </footer>
        </section>
    )
 
 }

export default Footer