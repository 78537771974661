import React from 'react'
import '../styles/Navigation.css'
import { AdminOnlyLink } from './AdminOnlyRoute'
const Navigation = () => {
  return (
    <div>
        <headers>
          
<nav className='navbar'>
  
{/* Logo */}
<div className='logoHolder'>
<a href='/'>
<img src={require('../Image/6.png')} width={'100%'}  height={50} alt='logo' />
</a>
</div>
<div className='phone'><h1>(713) - 873 - 1752</h1></div>

 <div> 
<ul className='nav-links'>
 
{/* uising checkbox hack */}
<input type='checkbox' id='checkbox_toggle'/>
<label for='checkbox_toggle' className='hamburger'>&#9776;</label>
{/* navigation menu */}
<div className='menu'>
<li><a href='/' className='navName' id="active1">Home</a></li>
<li className='services'>
<a href='/services' className='navName' id="active1">Services</a> 
</li>

<li className='services' id='navDrop'>
<a href='/gallery' className='navName' id="active1">Gallery</a>        
</li>
<li className='services'>
<a href='/contact' className='navName' id="active1">Contact</a>
 
</li>   

<li className='services' id='navDrop'>
<a href='/about' className='navName' id="active1">About</a>     

</li>
{/* 
<AdminOnlyLink>
 <li><a href="/profile" className='navName'>Admin</a></li>  
 </AdminOnlyLink> */}

{/* <div className='phone'><h1>7138731752</h1></div> */}
   </div> 
    </ul> 
</div>
</nav>
 
</headers>

    </div>
  )
}

export default Navigation