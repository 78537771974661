import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
export const firebaseConfig = {
    apiKey: "AIzaSyDESxwXZLftC2ERHHbuPv0rK677wyyl_UE",
    authDomain: "projectsam-d630f.firebaseapp.com",
    projectId: "projectsam-d630f",
    storageBucket: "projectsam-d630f.appspot.com",
    messagingSenderId: "333393207868",
    appId: "1:333393207868:web:e04701ab14cfefe6b19939"
};

 
 
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);

export default app;
