import React from 'react'
import '../styles/About.css'
const About = () => {
  return (
    <div>
    <div class='about-container'>
        <img src={require('../Image/contact.png')} alt='serviceImgage'/>
        <div class='text'>
            <h1>Project Tax Remodeling</h1>
        </div>
    </div>
    <div class='about-Details'>
        <img src={require('../Image/aboutImg.jpg')} alt='serviceImgage'/>
        <p>
            With over 20 years of experience, Tax offers residential and commercial services. We have worked throughout Houston, Texas, on various properties, helping the community by displaying customer satisfaction and high-quality service that are important to you and your family. Trust is everything to us. Working in the most populated and diverse city in Texas, we have worked with countless people who have enjoyed and been satisfied with the experience. Choose Tax; we don't just build projects, we build relationships.
        </p>
    </div>
</div>

  )
}

export default About