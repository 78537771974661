import React from 'react'
import '../styles/HomeComponent.css'
const HomeComponent = () => {
  return (
    <div> 
           <div className='servicesHomeContainer'>
        <h1>General Remodeling Services</h1>
        <div className='servicesImgHome'>
            <div className='service'>
            <img src={require('../Image/7.jpg')} className='sericeImg' alt='sericeImg'/>
            <h2>Repair & Remodeling</h2>
            {/* <p>Different families have different goals for their kitchens. Whatever you want to get out of your kitchen, we’ll make certain we achieve it.</p> */}

            <div className='btnLeabmore'><a href='/repair'>Lean More</a></div>

               </div> 

            <div className='service'>
            <img src={require('../Image/13.jpg')} className='sericeImg' alt='sericeImg'/>
            <h2>Bathroom</h2>
            {/* <p>Bathrooms are one of the most private spaces in our homes. Premier Remodeling can make your bathroom one of the most relaxing spots in your home.</p> */}
            <div className='btnLeabmore'><a href='/bathroom'>Lean More</a></div>

            </div> 
{/* 
            <div className='service'>
            <img src='https://images.unsplash.com/photo-1586023492125-27b2c045efd7?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTR8fGxpdmluZyUyMHJvb20lMjBpbnRlcmlvciUyMGRlc2lnbnxlbnwwfHwwfHx8MA%3D%3D' className='sericeImg' alt='sericeImg'/>
            <h2>Living Room</h2>
            <p>Many homeowners view living spaces as the parts that turn a house into a home. These spaces should provide the comfort you need so you can love your home all over again.</p>
            <div className='btnLeabmore'><a href='/livingroom'>Lean More</a></div>
            </div>  */}

            {/* <div className='service'>
            <img src={require('../Image/counter.JPG')} className='sericeImg' alt='sericeImg'/>
            <h2>Countertop</h2>
            <p>Outdoor spaces connect you with nature and serve as the perfect place to gather. Houston backyard renovation by Premier Remodeling will make your backyard the perfect retreat.</p>
            <div className='btnLeabmore'><a href='/outdoor'>Lean More</a></div>

            </div>  */}
  
            <div className='service'>
            <img src={require('../Image/16b.png')} className='sericeImg' alt='sericeImg'/>
            <h2>Flooring</h2>
            {/* <p>Some houses may be considered diamonds in the rough. Leave it up to our Houston remodeling contractors to transform your entire house without having to leave the memories behind.</p> */}
            <div className='btnLeabmore'><a href='/floor'>Lean More</a>
            </div>
            </div> 

            <div className='service'>
            <img src={require('../Image/frontcover.jpg')} className='sericeImg' alt='sericeImg'/>
            <h2>Fences</h2>
             <div className='btnLeabmore'><a href='/fences'>Lean More</a>
            </div>
            </div> 
  
  
        </div>        
        </div>
    </div>
  )
}

export default HomeComponent