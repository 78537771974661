import React, { useEffect, useState } from 'react'
import '../styles/Gallery.css'
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../Firebase';
const Gallery = () => {
const [getImage, setGetImage] = useState([]);
  useEffect(() => {
    const fetchVideo = async () => {
      try {
        const videoRef = collection(db, 'images');
        const querySnapshot = await getDocs(videoRef);
        const videoDocuments = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setGetImage(videoDocuments);
      } catch (error) {
        console.error('Error fetching video data:', error);
      }
    };
    fetchVideo();
  }, []);

  return (
    <div className='galleryHomeContainer'>
      <div className='imgContainerSer'>
        <img src={require('../Image/bath.png')} width={'100%'}   height={700} alt='serviceImgage'/>
         <div className='text'>
         <h1>Remodeling Gallery</h1>
        {/* <h2>View our wide range of services</h2> */}
         </div>
      </div>

      <div class='galleryContainer'>
     <div class='gallery'>

     {getImage.map((img, index)=>(
        <>
         
     <div key={index}>
      <img src={img.imageFile} alt='galleryimage 1'/>
             
          </div>
      
        </>
       ))}

       

      
    </div>
  </div>
    </div>
  )
}

export default Gallery