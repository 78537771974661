import React, { useRef } from 'react'
import '../styles/Contact.css'
import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";
import { FaEnvelope, FaPhoneAlt } from 'react-icons/fa';
import { GoLocation } from 'react-icons/go';
const Contact = () => {
    const form = useRef();
  
    const sendEmail = (e) => {
      e.preventDefault();
      console.log(form.current);
  
      emailjs
        .sendForm('service_792mvv4', 'template_dmrfj4f', form.current, '2QpOtXelYFS64_Afg')
        .then(
          (result) => {
            toast.success("Message sent successfully");
          },
          (error) => {
            toast.error(error.text);
          }
        );
      e.target.reset();
    };
  
  return (
    <div className='contactContainer'>
        <div className='imgContainerSer'>
        <img src={require('../Image/contact.png')} width={'100%'}   height={700} alt='serviceImgage'/>
         <div className='text'>
         {/* <h1>Remodeling Gallery</h1> */}
        {/* <h2>View our wide range of services</h2> */}
         </div>
      </div>

           <section>
        <div className='contactContent' id='contactCont'>
          <h4>Contact us</h4>
          <div className='section'>
          <form ref={form} onSubmit={sendEmail}>
                 {/* <label>Name</label> */}
                <input
                  type="text"
                  name="from_name"
                  placeholder="Full Name"
                  required
                 />
                {/* <label>Email</label> */}
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  required
                />
                {/* <label>Phone</label> */}
                <input
                  type="number"
                  name="phone"
                  placeholder="Phone"
                  required
                />
                {/* <label>Subject</label> */}
                <input
                  type="text"
                  name="subject"
                  placeholder="Subject"
                  required
                />
                <label>Message</label>
                <textarea name="message" cols="30" rows="10"></textarea>
                <button className="btn1" style={{color: '#494949'}}><h3>Send Message</h3></button>                          
             </form>    
             
             <div className='details'>
               <h3>Our Contact Information</h3>
              <p>Fill the form or contact us via other channels listed below</p>
              <div className='icons'>
                <span>
                  <FaPhoneAlt />
                  <p>(713) - 873 - 1752</p>                  
                </span>
                <span>
                  <FaEnvelope />
                  <p>taxremodelingprojects@gmail.com</p>
                </span>
                <span>
                  <GoLocation />
                  <p>Houston TX, USA</p>
                </span>   
                 {/* <span>
                  <FaTwitter />
                  <p>@ZinoTrust</p>
                </span>               */}
              </div>
           </div>                   
          </div>
        </div>
      </section> 
     </div>
  )
}

export default Contact