import React from 'react'

const Bathroom = () => {
  return (
    <div>
      <div className='kitchenContainer'>
       <div className='imgContainerSer'>
        <img src={require('../Image/bath.png')} width={'100%'}   height={700} alt='serviceImgage'/>
         <div className='text'>
         <h1>Bathroom Remodeling</h1>
        {/* <h2>View our wide range of services</h2> */}
         </div>
      </div>
      <div className='kitchenDetails'>
      <img src={require('../Image/bath2.jpg')}   alt='serviceImgage'/>
      <h2>
      Updating the shower's layout, size, or materials can improve comfort levels. A well-designed and updated bathroom can significantly increase the value of a home. Remodeling bathrooms is often considered one of the top projects for return on investment when selling a home. We're here to help!
    <div style={{opacity: 0}}>-</div>
    <p>-Showers</p>
    <p>-Backsplash </p>
    <p>-Spc floor </p>
    <p>-Installing cabinets</p>
    <p>-Installing toilets</p>
    </h2>  
  </div>
    </div>
    </div>
  )
}

export default Bathroom