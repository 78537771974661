import React from 'react'
import '../styles/Services.css'
const Services = () => {
  return (
    <div className='serviceContainer'>
      <div className='imgContainerSer'>
    <img src={require('../Image/7a.png')} alt='serviceImgage'/>
    <div className='text'>
        <h1>Repair & Remodeling Services</h1>
        <h2>View our wide range of services</h2>
    </div>
</div>

          <div className='servicestypes'>
         <div className='servicesImgHome'>
            <div className='service'>
            <a href="/repair" ><img src={require('../Image/7.jpg')} className='sericeImg' alt='sericeImg'/></a>
            <h2>Repair & remodel</h2>  
                </div> 

            <div className='service'>
       
            <a href="/bathroom"> <img src={require('../Image/10.jpg')} className='sericeImg' alt='sericeImg'/></a>
            <h2>Bathroom Remodeling</h2>
           
            {/* <div className='btnLeabmore'><a href='/services'>Lean More</a></div> */}

            </div> 

   
            <div className='service'>
            <a href="/floor" ><img src={require('../Image/16b.png')} className='sericeImg' alt='sericeImg'/></a>
            <h2>Floor Remodeling</h2>
            
            {/* <div className='btnLeabmore'><a href='/services'>Lean More</a></div> */}

            </div> 

            <div className='service'>
            <a href="/frontcover" ><img src={require('../Image/frontcover.jpg')} className='sericeImg' alt='sericeImg'/></a>
            <h2>Fences</h2>
            
            {/* <div className='btnLeabmore'><a href='/fences'>Lean More</a></div> */}

            </div> 



            {/* <div className='service'>
            <a href="/livingroom" ><img src={require('../Image/living2.JPG')} className='sericeImg' alt='sericeImg'/></a>
            <h2>Living Room Remodeling</h2>
            
            <div className='btnLeabmore'><a href='/services'>Lean More</a></div>

            </div>  */}

            {/* <div className='service'>
            <a href="/outdoor" ><img src={require('../Image/out2.JPG')} className='sericeImg' alt='sericeImg'/></a>
            <h2>Outdoor Remodeling</h2>
            
            <div className='btnLeabmore'><a href='/services'>Lean More</a></div>

            </div>  */}

            {/* <div className='service'>
            <a href="/floor" ><img src={require('../Image/16b.png')} className='sericeImg' alt='sericeImg'/></a>
            <h2>Floors Remodeling</h2>
            
            <div className='btnLeabmore'><a href='/services'>Lean More</a></div>

            </div>  */}

            {/* <div className='service'>
            <a href="/tile" ><img src={require('../Image/Tiles2.JPG')} className='sericeImg' alt='sericeImg'/></a>
            <h2>Ceramic & Porcelain Tiles</h2>
            
            <div className='btnLeabmore'><a href='/services'>Lean More</a></div>

            </div> */}
            
            {/* <div className='service'>
            <a href="/countertop" ><img src={require('../Image/counter2.JPG')} className='sericeImg' alt='sericeImg'/></a>
            <h2>Countertops</h2>
            
            <div className='btnLeabmore'><a href='/services'>Lean More</a></div>

            </div>  */}

            {/* <div className='service'>
            <a href="/backsplash" ><img src={require('../Image/back.JPG')} className='sericeImg' alt='sericeImg'/></a>
            <h2>Backsplash</h2>
            
            <div className='btnLeabmore'><a href='/services'>Lean More</a></div>

            </div>  */}
        </div>        
        </div>
      </div>
   )
}

export default Services