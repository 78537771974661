import React from 'react'
import '../styles/ServicesTypes.css'

const Repair = () => {
  return (
    <div className='kitchenContainer'>
       <div className='imgContainerSer'>
        <img src={require('../Image/7a.png')} width={'100%'}   height={700} alt='serviceImgage'/>
         <div className='text'>
         <h1>Repair & Remodeling</h1>
        {/* <h2>View our wide range of services</h2> */}
         </div>
      </div>
      <div className='kitchenDetails'>
      <img src={require('../Image/12.jpg')}   alt='serviceImgage'/>
    <h2>
    Maintenance may be necessary to repair damage caused by age, weather conditions, or other issues. We are well-suited and trusted for repairs. Addressing these problems through remodeling not only restores the functionality of the home but also improves its overall condition and appearance. By improving various properties, we are well-trusted and can guarantee a good job.
    </h2>
  </div>
    </div>
  )
}

export default Repair