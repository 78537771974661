import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Navigation from './components/Navigation'
import Home from './Pages/Home'
import Contact from './Pages/Contact'
import Gallery from './Pages/Gallery'
import Services from './Pages/Services'
import About from './Pages/About'
import Kitchen from './Pages/Kitchen'
import Bathroom from './Pages/Bathroom'
import LivingRoom from './Pages/LivingRoom'
import Outdoor from './Pages/Outdoor'
import House from './Pages/House'
import Room from './Pages/Room'
import Footer from './Pages/Footer'
import Floor from './Pages/Floor'
import Tiles from './Pages/Tiles'
import Countertop from './Pages/Countertop'
import Backsplash from './Pages/Backsplash'
import Repair from './Pages/Repair'
import Login from './auth/Login'
import { AuthContextProvider } from './auth/AuthContext'
import Profile from './Pages/Profile'
import FrontCover from './Pages/FrontCover'
  
const App = () => {
  return (
    <>
    <BrowserRouter>
    <AuthContextProvider>
    <Navigation/>
    <Routes>
       <Route path='/' element={<Home/>} />
       <Route path='/loginOnlyPg' element={<Login/>} />
       <Route path='/contact' element={<Contact/>} />
       <Route path='/gallery' element={<Gallery/>} />
       <Route path='/services' element={<Services/>} />
       <Route path='/about' element={<About/>} />
       <Route path='/fences' element={<FrontCover/>} />
       <Route path='/kitchen' element={<Kitchen/>} />
       <Route path='/bathroom' element={<Bathroom/>} />
       <Route path='/livingroom' element={<LivingRoom/>} />
       <Route path='/outdoor' element={<Outdoor/>} />
       <Route path='/room' element={<Room/>} />
       <Route path='/house' element={<House/>} />
       <Route path='/floor' element={<Floor/>} />
       <Route path='/tile' element={<Tiles/>} />
       <Route path='/countertop' element={<Countertop/>} />
       <Route path='/backsplash' element={<Backsplash/>} />
       <Route path='/repair' element={<Repair/>} />
       <Route path='/adminOnlyprofile' element={<Profile/>} />    
    </Routes>
    </AuthContextProvider>
    <Footer/>
    </BrowserRouter>
    </>
  )
}

export default App