import React, { useRef } from 'react'
import '../styles/Home.css'
//  import { FaPhoneAlt, FaEnvelope } from "react-icons/fa";
// import { GoLocation } from "react-icons/go";
import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";
import HomeComponent from '../components/HomeComponent';
 

const Home = () => {

  return (
    <div className='homeContainer'>
        <div className='homeImgContainer'>
        <div>
            <img src={require('../Image/12.jpg')} width={'100%'} height={800} className='' alt='homeimage'/>
            <div className='nameslogan' id='homeProject'>
            <h1>Project Tax Remodeling</h1>
            <h2>We  don't just build projects, we build relationships.</h2>
            </div>
            
           <div className='contactinHome'> <Contact/></div>
         </div> 
         {/* <div className='imgContainerSer'>
        <img src={require('../Image/12.jpg')} width={'100%'}   height={700} alt='serviceImgage'/>
         <div className='text'>
         <h1>Project Tax Remodeling</h1>
        <h2>We just don't build projects, we build relationships</h2>
         </div>
         <div className='contactinHome'> <Contact/></div>

      </div> */}
        </div>
        <div className='homeComponents'><HomeComponent/></div>
    </div>
  )
}

const Contact = () => {
    const form = useRef();
  
    const sendEmail = (e) => {
      e.preventDefault();
      console.log(form.current);
  
      emailjs
        .sendForm('service_792mvv4', 'template_dmrfj4f', form.current, '2QpOtXelYFS64_Afg')
        .then(
          (result) => {
            toast.success("Message sent successfully");
          },
          (error) => {
            toast.error(error.text);
          }
        );
      e.target.reset();
    };
  
    return (
      <section>
        <body>

        <div className='contact' id='contactConent'>
          <h4>Request For a Free Estimate</h4>
          <div className='section'>
            <form ref={form} onSubmit={sendEmail}>
                 {/* <label>Name</label> */}
                <input
                  type="text"
                  name="from_name"
                  placeholder="Full Name"
                  required
                 />
                {/* <label>Email</label> */}
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  required
                />
                {/* <label>Phone</label> */}
                <input
                  type="number"
                  name="phone"
                  placeholder="Phone"
                  required
                />
                {/* <label>Subject</label> */}
                <input
                  type="text"
                  name="subject"
                  placeholder="Subject"
                  required
                />
                <label>Message</label>
                <textarea name="message" cols="30" rows="10"></textarea>
                <button className="btn1" style={{color: '#494949'}}><h3>Send Message</h3></button>                          
             </form>    
     
          </div>
        </div>
        </body>
      </section>
    );
  };

 
export default Home