import React from 'react'

const Floor = () => {
  return (
    <div>
        <div className='kitchenContainer'>
       <div className='imgContainerSer'>
        <img src={require('../Image/Floor/Floor.png')} width={'100%'}   height={700} alt='serviceImgage'/>
         <div className='text'>
         <h1>Floor Remodeling</h1>
        {/* <h2>View our wide range of services</h2> */}
         </div>
      </div>
      <div>
      <div className='kitchenDetails'>
      <img src={require('../Image/16b.png')}   alt='serviceImgage'/>
 
   <h2>
    When improving the value of your property, flooring and trim are among the most important aspects. The right flooring and trim can enhance comfort levels and significantly impact the resale value of a property. With Tax, we can guarantee to make your home look absolutely beautiful.
    <div style={{opacity: 0}}>-</div>
    <p>-Engineered wood</p>
    <p>-Laminate floor</p>
    <p>-Spc floor </p>
    <p>-Hardwood</p>
    <p>-Porcelain</p>
    <p>-Ceramic </p>
    <p>-Travertine</p>
    </h2>   
    
   </div>  
   
  </div>
    
    </div>
    </div>
  )
}

export default Floor