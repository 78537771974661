import React from 'react'

const FrontCover = () => {
  return (
    <div>
      <div className='kitchenContainer'>
       <div className='imgContainerSer'>
        <img src={require('../Image/frontcover1.jpg')} width={'100%'}   height={700} alt='serviceImgage'/>
         <div className='text'>
         <h1>Fences Remodeling</h1>
        {/* <h2>View our wide range of services</h2> */}
         </div>
      </div>
      <div className='kitchenDetails'>
      <img src={require('../Image/fence.jpg')}   alt='serviceImgage'/>
      <h2>
      Natural causes leave you without a fence? As we help repair and replace fences within our community, we’re also ready to enhance your property’s value and make your outdoor space more enjoyable. We guarantee that your new fence will not only meet but exceed your expectations    <div style={{opacity: 0}}>-</div>
     
    </h2>  
  </div>
    </div>
    </div>
  )
}

export default FrontCover